// Copy a URL from a field to share
import $ from 'jquery';

export default function shareURL (trigger) {
    $(trigger).on('click', function(e) {
        var copyURL = $(this).data('url');
        var defaultText = $(this).html();
        navigator.clipboard.writeText(copyURL).then(() => {
            // Alert the user that the action took place.
            // Nobody likes hidden stuff being done under the hood!
            $(this).text("Copied").removeClass("button--primary").addClass("button--disabled").delay(3000).queue(
            	function(){
  					$(this).addClass("button--primary").removeClass("button--disabled").html(defaultText);
				});
        });
    });
}