import $ from 'jquery';
import skipLinks from './utils/skipLinks';
import iframer from './utils/iframer';
// import closeNotification from './utils/closeNotification';
import mNav from './utils/mNav';
// import childTabIndex from './utils/childTabIndex';
// import searchExpand from './utils/searchExpand';
// import setupSharing from './utils/social';
// import equalHeight from './utils/equalHeight';
// import listShowHide from './utils/listShowHide';
// import googleMaps from './utils/googleMaps';
// import removeNovalidate from './utils/removeNovalidate';
import multiNav from './utils/multiNav';
import cookies from './utils/cookies';
// import modalVideo from './utils/modalVideo';
import shareURL from './utils/shareURL';

function globals () {

    // modalVideo

    // modalVideo('buttonPlay','modal','modalClose');
    // modalVideo('#buttonPlay','#modal','#modalClose');

    // Cookie bar
    cookies();

    // iframe video in body content
    iframer();

    // Small Screen Navigation
    mNav(
        '#navigation-primary-toggle',
        'navigation-primary-toggle--open',
        '.ui-canvas',
        'menu-open'
    );

    // Multi Navigation
    multiNav(
        '#navigation-primary',
    );

    // Set child tab index for nav
    // childTabIndex(
    //     '#navigation-primary-toggle',
    //     '#navigation-primary',
    //     'navigation-primary--active',
    //     960
    // );

    // Filter showHide
    mNav(
        '#filter-trigger',
        'filter__heading--open',
        '#filter-reveal',
        'filter__reveal--open'
    );

    // // Search showHide
    // mNav(
    //     '#header-search-toggle',
    //     'header-search-toggle--open',
    //     '#header-search',
    //     'header-search--open'
    // );

    // Search expand
    // searchExpand(
    //     '.js-search',
    //     '.js-search',
    //     'js-search--active'
    // );

    // Events filter
    // mNav(
    //     '.filterBlock__title',
    //     'active',
    //     '.filters__wrap',
    //     'active'
    // );

    // Set child tab index for nav
    // childTabIndex(
    //     '.filterBlock__title',
    //     '.filters__wrap',
    //     'active',
    //     780
    // );

    // social
    // setupSharing();

    // equal height chart titles
    // equalHeight('.l-2up, .l-3up, .l-4up, .l-2-3up', '.m-intro__subheading');

    // hide children after x items and show "view more" button
    // listShowHide();

    // google maps - fires on all instances of maps so handles multiples
    // googleMaps();

    // removes the novalidate attribute on comment forms to enable client side validation
    // removeNovalidate();

    // share url
    shareURL('.shareURL');
}

$(function run () {
    console.log('ᕕ( ᐛ )ᕗ Running...');
    globals();
});
